// @flow

import * as React from 'react';
import times from 'lodash/times';
import FontAwesomeIcon from 'common/components/FontAwesomeIcon';

type Props = {|
  score: number,
  max: number
|};

class Rating extends React.Component<Props> {
  static defaultProps: {| max: number, score: number |} = {
    score: 0,
    max: 5
  };

  render(): React.Node {
    const score = this.props.score;
    const max = this.props.max;

    const fraction = score - Math.floor(score);

    const fullStarCount = Math.floor(score);
    const emptyStarCount = max - Math.ceil(score);

    const partialStar = (function () {
      if (fraction === 0) {
        return null;
      } else if (fraction <= 0.25) {
        return (
          <FontAwesomeIcon
            icon={{
              legacyClassName:
                'b-rating__star b-rating__star--empty fa fa-star-o'
            }}
          />
        );
      } else if (fraction <= 0.75) {
        return (
          <FontAwesomeIcon
            icon={{
              legacyClassName:
                'b-rating__star b-rating__star--half fa fa-star-half-o'
            }}
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            icon={{
              legacyClassName: 'b-rating__star b-rating__star--full fa fa-star'
            }}
          />
        );
      }
    })();

    return (
      <div className="b-rating">
        {times(fullStarCount, index => {
          return (
            <FontAwesomeIcon
              icon={{
                legacyClassName:
                  'b-rating__star b-rating__star--full fa fa-star'
              }}
              key={`star-full:${index}`}
            />
          );
        })}
        {partialStar}
        {times(emptyStarCount, index => {
          return (
            <FontAwesomeIcon
              icon={{
                legacyClassName:
                  'b-rating__star b-rating__star--empty fa fa-star-o'
              }}
              key={`star-empty:${index}`}
            />
          );
        })}
      </div>
    );
  }
}

export default Rating;
