// @flow
import * as React from 'react';
import { FontAwesomeIcon as FontAwesomeIconV5 } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

// CSS is added already of server side, so we don't need to re-add it here.
window.FontAwesomeConfig.autoAddCss = false;

export opaque type Icon = mixed;
export type Props = {
  // Enforce that `name` prop is _not_ given as that was only used for old
  // FAIcon times and would signal that some code change was broken.
  name?: empty,
  className?: string,
  icon:
    | Icon
    | {|
        legacyClassName: string
      |},
  fixedWidth?: boolean,
  spin?: boolean,
  pulse?: boolean,
  ...
};

type LegacyProps = {
  ...Props,
  icon: {| legacyClassName: string |},
  ...
};
function LegacyIcon(props: LegacyProps) {
  const { icon, className, spin, fixedWidth, pulse, ...otherProps } = props;

  const classNameForIcon = cx(icon.legacyClassName, className, {
    'fa-fw': fixedWidth,
    'fa-spin': spin,
    'fa-pulse': pulse
  });

  // eslint-disable-next-line rulesdir/no-plain-icons -- This is expected to all be legacy icon calls
  return <i {...otherProps} className={classNameForIcon} />;
}

export default function FontAwesomeIcon(props: Props): React.Node {
  const { icon, ...restProps } = props;
  // $FlowIgnore[incompatible-type] -- the Icon type is `mixed` so we can't get any better test than this
  if (icon.legacyClassName) {
    return (
      <LegacyIcon
        {...restProps}
        icon={{
          legacyClassName: icon.legacyClassName
        }}
      />
    );
  }
  return <FontAwesomeIconV5 {...restProps} icon={icon} />;
}

// Light icons
import { faBirthdayCake } from '@fortawesome/pro-light-svg-icons/faBirthdayCake';
export const lightBirthdayCake: Icon = faBirthdayCake;

import { faBolt } from '@fortawesome/pro-light-svg-icons/faBolt';
export const lightBolt: Icon = faBolt;

import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
export const lightBriefcase: Icon = faBriefcase;

import { faCalendarAlt as faCalendarAltLight } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
export const lightCalendarAlt: Icon = faCalendarAltLight;

import { faCheck as faLightCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
export const lightCheck: Icon = faLightCheck;

import { faClock as faClockLight } from '@fortawesome/pro-light-svg-icons/faClock';
export const lightClock: Icon = faClockLight;

import { faCoins } from '@fortawesome/pro-light-svg-icons/faCoins';
export const lightCoins: Icon = faCoins;

import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
export const lightEnvelope: Icon = faEnvelope;

import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
export const lightHandshake: Icon = faHandshake;

import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
export const lightHome: Icon = faHome;

import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
export const lightPhone: Icon = faPhone;

import { faPiggyBank } from '@fortawesome/pro-light-svg-icons/faPiggyBank';
export const lightPiggyBank: Icon = faPiggyBank;

import { faStar } from '@fortawesome/pro-light-svg-icons/faStar';
export const lightStar: Icon = faStar;

import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
export const lightTag: Icon = faTag;

import { faTags } from '@fortawesome/pro-light-svg-icons/faTags';
export const lightTags: Icon = faTags;

import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
export const lightTimes: Icon = faTimes;

import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
export const lightTimesCircle: Icon = faTimesCircle;

import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
export const lightTrash: Icon = faTrashAlt;

import { faUser as faUserLight } from '@fortawesome/pro-light-svg-icons/faUser';
export const lightUser: Icon = faUserLight;

import { faUserFriends } from '@fortawesome/pro-light-svg-icons/faUserFriends';
export const lightUserFriends: Icon = faUserFriends;

// Regular icons
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
export const regularCheck: Icon = faCheck;

import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
export const regularDownload: Icon = faDownload;

import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
export const regularEdit: Icon = faEdit;

import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
export const regularExclamationTriangle: Icon = faExclamationTriangle;

import { faExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
export const regularExternalLink: Icon = faExternalLink;

import { faFilm } from '@fortawesome/pro-regular-svg-icons/faFilm';
export const regularFilm: Icon = faFilm;

import { faList } from '@fortawesome/pro-regular-svg-icons/faList';
export const regularList: Icon = faList;

import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
export const regularMinus: Icon = faMinus;

import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
export const regularPlus: Icon = faPlus;

import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
export const regularSearch: Icon = faSearch;

import { faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
export const regularSpinner: Icon = faSpinner;

// Solid icons
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
export const solidAngleLeft: Icon = faAngleLeft;

import { faBolt as faSolidBolt } from '@fortawesome/pro-solid-svg-icons/faBolt';
export const solidBolt: Icon = faSolidBolt;

import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
export const solidCalendarAlt: Icon = faCalendarAlt;

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
export const solidCaretDown: Icon = faCaretDown;

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
export const solidCaretUp: Icon = faCaretUp;

import { faCheck as faSolidCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
export const solidCheck: Icon = faSolidCheck;

import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
export const solidCheckSquare: Icon = faCheckSquare;

import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
export const solidDollarSign: Icon = faDollarSign;

import { faEnvelope as faSolidEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
export const solidEnvelope: Icon = faSolidEnvelope;

import { faExclamationTriangle as faSolidExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
export const solidExclamationTriangle: Icon = faSolidExclamationTriangle;

import { faHatChef } from '@fortawesome/pro-solid-svg-icons/faHatChef';
export const solidHatChef: Icon = faHatChef;

import { faHome as faSolidHome } from '@fortawesome/pro-solid-svg-icons/faHome';
export const solidHome: Icon = faSolidHome;

import { faList as faSolidList } from '@fortawesome/pro-solid-svg-icons/faList';
export const solidList: Icon = faSolidList;

import { faMapMarker } from '@fortawesome/pro-solid-svg-icons/faMapMarker';
export const solidMapMarker: Icon = faMapMarker;

import { faSearch as faSolidSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
export const solidSearch: Icon = faSolidSearch;

import { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons/faStar';
export const solidStar: Icon = faSolidStar;

import { faTags as faSolidTags } from '@fortawesome/pro-solid-svg-icons/faTags';
export const solidTags: Icon = faSolidTags;

import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
export const solidUser: Icon = faUser;

import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils';
export const solidUtensils: Icon = faUtensils;
