// @flow

import * as React from 'react';
import classnames from 'classnames';

// NOTE: DEPRECATED
// This is for FontAwesome version 4.
// Prefer FontAwesomeIcon, which is version 5.
export default class FAIcon extends React.Component<{
  name: string,
  fixedWidth?: boolean,
  spin?: boolean,
  pulse?: boolean,
  className?: string,
  ...
}> {
  render(): React.Node {
    const { name, fixedWidth, className, spin, pulse, ...props } = this.props;

    const classes = classnames(
      'fa',
      `fa-${name}`,
      {
        'fa-fw': fixedWidth,
        'fa-spin': spin,
        'fa-pulse': pulse
      },
      className
    );

    // eslint-disable-next-line rulesdir/no-plain-icons -- TODO: Replace FAIcon usages altogether
    return <i {...props} className={classes} />;
  }
}

export type StackProps = {|
  foregroundIcon: string,
  backgroundIcon: string
|};

export function IconStack(props: StackProps): React.Node {
  return (
    <span className="fa fa-stack">
      {/* eslint-disable-next-line rulesdir/no-faicon -- This needs to be fixed when we can use v6 icons */}
      <FAIcon name={props.backgroundIcon} className="fa-stack-2x" />
      {/* eslint-disable-next-line rulesdir/no-faicon -- This needs to be fixed when we can use v6 icons */}
      <FAIcon name={props.foregroundIcon} className="fa-stack-1x" />
    </span>
  );
}
